import { createStore } from "vuex";

export default createStore({
  state: {
    discordUser: null,
    twitterUser: null,
    twitterFollow: null,
    discordJoin: false,
    login: null,
  },
  getters: {
    getLogin(state) {
      return state.login;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
