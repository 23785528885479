import Repository from "./Repository";

export default {
  getUser() {
    return Repository.get(`/users/user`);
  },
  startUserQuiz(data) {
    return Repository.post(`/users/start-quiz`, data);
  },
  checkUserQuiz(quizId) {
    return Repository.get(`/users/check-quiz-started/${quizId}`);
  },
  addQuestionAnswer(data) {
    return Repository.post(`/users/add-question-answer`, data);
  },
  getUserResumedAnswers(userQuizId) {
    return Repository.get(`/users/resumed-answers/${userQuizId}`);
  },
  submitQuiz(data) {
    return Repository.post(`/users/submit-quiz`, data);
  },
};
