import Repository from "./Repository";

export default {
  checkQuiz(slug) {
    return Repository.get(`/quiz/check/${slug}`);
  },
  getQuiz(slug) {
    return Repository.get(`/quiz/${slug}`);
  },
};
