<template>
  <div class="container-main-list">
    <h2 class="container-main-list-title">
      In Etherneals favour, we are glad to announce you that the quiz has begun.
      There are a few steps to become eligible for the 15 x 500 ETNY and 3 free
      NFTs rewards:
    </h2>

    <ol class="main-list">
      <li class="main-list-item">
        Join our
        <a
          href="https://discord.com/channels/976861155266019389/977339218302996540"
          target="_blank"
        >
          Discord server</a
        >
      </li>
      <li class="main-list-item">Login with Discord</li>
      <li class="main-list-item">
        Follow us on
        <a href="https://twitter.com/EthernealsNFT" target="_blank">Twitter</a>
      </li>
      <li class="main-list-item">
        Upload Twitter follow proof on channel
        <a
          href="https://discord.com/channels/976861155266019389/1008997781886738482"
          target="_blank"
          >#proof-channel</a
        >
      </li>
      <li class="main-list-item">Answer quiz questions correctly</li>
    </ol>
    <div
      class="text-center my-5 font-weight-bold"
      style="color: #f89430; font-size: 20px"
    >
      The NFTs will be airdropped at a later date that will be announced on
      <a
        href="https://discord.com/channels/976861155266019389/977339582020456488"
        target="_blank"
      >
        #announcements</a
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>
<style lang="scss">
.container-main-list {
  max-width: 1000px;
  margin: auto;
  &-title {
    color: #f89430;
    text-align: center;
    margin-bottom: 30px;
  }
  .main-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    &-item {
      font-size: 20px;
      font-weight: 700;
      a {
        text-decoration: none;
        color: #0c86ff;
      }
    }
  }
}
a {
  text-decoration: none;
  color: #0c86ff;
}
</style>
