<template>
  <v-card class="stepper-card" width="100%" max-width="900px">
    <v-card-item>
      <div class="stepper-header">
        <div class="stepper-header-bar"></div>
        <div
          v-for="(item, i) in questions"
          class="stepper-item"
          :class="{ current: step == i, checked: item['checked'] }"
          :key="i"
        >
          <div class="stepper-item-counter" @click="changeQuestion(i)">
            <font-awesome-icon
              v-if="item['checked']"
              style="font-size: 20px"
              icon="fa-solid fa-check"
            />
            <span v-else class="number">
              {{ i + 1 }}
            </span>
          </div>
        </div>
      </div>
      <transition-group tag="div" name="slide">
        <div class="slide" v-for="(item, i) in questions" :key="item.id">
          <div v-if="step == i" color="transparent">
            <div class="mb-5" style="font-size: 1.25rem; font-weight: 700">
              {{ item.question }}
            </div>
            <div class="inner">
              <div v-for="a in item.quizzesQuestionsAnswer" :key="a.id">
                <input
                  type="radio"
                  name="item.question"
                  :checked="a.selected"
                  @click="selectAnswer(item, a)"
                />
                {{ a.answer }}<br />
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </v-card-item>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="changeQuestion(step - 1)" :disabled="step == 0">
        Back
      </v-btn>
      <v-btn
        @click="changeQuestion(step + 1)"
        v-if="step < questions.length - 1"
      >
        Next
      </v-btn>
      <v-btn v-else class="blue-btn" @click="submit">
        <font-awesome-icon
          v-if="loading"
          style="font-size: 20px"
          icon="fa-solid fa-spinner"
          spin-pulse
        />
        <span v-else>Submit</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  addQuestionAnswer,
  getQuiz,
  getUserResumedAnswers,
  startUserQuiz,
} from "@/assets/scripts/functions";
import { computed, defineComponent, onMounted, ref, toRefs } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    started: {
      type: Boolean,
      default: false,
    },
    resumed: {
      type: Boolean,
      default: false,
    },
    quiz: { type: Object, requird: true },
    userQuiz: { type: Object, requird: true },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore();

    const { started, quiz, resumed, userQuiz } = toRefs(props);

    const step = ref(0);
    const questions = ref([]);
    const newUserQuiz = ref(userQuiz.value);

    const discordUser = computed(() => store.state.discordUser);

    onMounted(() => {
      if (started.value) startQuiz();
      else if (resumed.value) resumeQuiz();
    });

    const startQuiz = async () => {
      let res;
      res = await getQuiz(quiz.value.slug);
      if (res.err) return;
      questions.value = res.quizzesQuestion;
      res = await startUserQuiz({ quiz: quiz.value });
      if (res.err) return;
      newUserQuiz.value = res;
    };

    const resumeQuiz = async () => {
      let res;
      res = await getQuiz(quiz.value.slug);
      if (res.err) return;
      questions.value = res.quizzesQuestion;
      res = await getUserResumedAnswers(newUserQuiz.value.id);
      if (res.err) return;
      for (const question of questions.value) {
        const findQ = res.find((el) => el.quizzesQuestion.id == question.id);

        if (findQ) {
          question["checked"] = true;
          const index = question.quizzesQuestionsAnswer.findIndex(
            (el) => el.id === findQ.questionsAnswer.id
          );
          if (index > -1)
            question.quizzesQuestionsAnswer[index]["selected"] = true;
        }
      }
    };

    const selectAnswer = async (q, a) => {
      const res = addQuestionAnswer({
        usersQuiz: newUserQuiz.value,
        quizzesQuestion: { id: q.id },
        questionsAnswer: { id: a.id },
      });
      if (!res.err) {
        a["selected"] = true;
        q["checked"] = true;
      }
    };

    const changeQuestion = (i) => {
      step.value = i;
      randomSort();
    };
    const randomSort = () => {
      for (
        let i = questions.value[step.value].quizzesQuestionsAnswer.length - 1;
        i > 0;
        i--
      ) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = questions.value[step.value].quizzesQuestionsAnswer[i];
        questions.value[step.value].quizzesQuestionsAnswer[i] =
          questions.value[step.value].quizzesQuestionsAnswer[j];
        questions.value[step.value].quizzesQuestionsAnswer[j] = temp;
      }
    };

    const submit = () => {
      context.emit("submit", { userQuiz: newUserQuiz.value });
    };

    return {
      step,
      questions,
      discordUser,
      selectAnswer,
      changeQuestion,
      submit,
    };
  },
});
</script>

<style lang="scss">
$default: #070e1d;
$blue: #0c86ff;
$transiton: all 500ms ease;
.slide {
  backface-visibility: hidden;
  z-index: 1;
}

.slide-move {
  transition: all 600ms ease-in-out 50ms;
}

.slide-enter-active {
  transition: all 400ms ease-out;
}

.slide-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}

.stepper-card {
  background: rgba(0, 0, 0, 0.5) !important;
  transition: $transiton !important;
  padding: 20px;
  .stepper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    margin-bottom: 24px;
    &-bar {
      position: absolute;
      width: 100%;
      height: 1px;
      background: white;
    }
    .stepper-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $default;
      transition: $transiton;

      &-counter {
        height: 50px;
        width: 50px;
        display: grid;
        place-items: center;
        background-color: #fff;
        border-radius: 100%;
        border: 2px solid $default;
        position: relative;

        .icon-success {
          position: absolute;
          opacity: 0;
          transform: scale(0);
          width: 24px;
          transition: $transiton;
        }

        .number {
          font-size: 18px;
          transition: $transiton;
        }
      }
    }
  }
}

.stepper-item.checked {
  .stepper-item-counter {
    border-color: $blue;
    background-color: #0a3c74;
    color: #fff;
    font-weight: 600;
  }
}

.stepper-item.current {
  .stepper-item-counter {
    border-color: $blue;
    background-color: $blue;
    color: #fff;
    font-weight: 600;
  }

  .stepper-item-title {
    color: #818181;
  }
}

.stepper-pane {
  color: #333;
  text-align: center;
  padding: 120px 60px;
  box-shadow: 0 8px 12px rgba($color: #000000, $alpha: 0.09);
  margin: 40px 0;
}

@media screen and (min-width: 450px) {
  .stepper-card {
    transition: $transiton !important;
  }
}
.controls {
  display: flex;
}
</style>
