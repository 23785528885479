<template>
  <v-app>
    <v-app-bar style="box-shadow: none; background: no-repeat">
      <div class="main-logo">
        <v-img
          width="50px"
          max-width="50px"
          src="./assets/images/logo.svg"
        ></v-img>

        <div class="main-logo-title">Etherneals NFT</div>
      </div>
      <v-btn
        v-if="$store.state.login"
        variant="text"
        style="
          font-size: 12px;
          text-transform: none;
          margin-left: auto;
          margin-right: 0;
          background: none;
        "
        @click="logOut"
      >
        Disconnect
      </v-btn>
    </v-app-bar>
    <v-main class="custom-main align-center ma-auto px-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore();
    const { cookies } = useCookies();

    const logOut = async () => {
      store.state.twitterFollow = null;
      store.state.discordJoin = false;
      store.state.discordUser = null;
      store.state.twitterUser = null;
      store.state.login = null;
      cookies.remove("jwt");
      cookies.remove("twitter_access");
      window.localStorage.clear();
    };
    return {
      logOut,
    };
  },
});
</script>
<style lang="scss">
.custom-main {
  max-width: 1400px !important;
  width: 100%;
}
.main-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  &-title {
    margin-left: 15px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}
@media screen and (max-width: 991px) {
  .main-logo-title {
    font-size: 14px;
  }
}
</style>
