// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
const myCustomDarkTheme = {
  dark: true,
  colors: {
    background: "#070e1d",
  },
};

export default createVuetify({
  icons: true,
  theme: {
    defaultTheme: "myCustomDarkTheme",
    themes: {
      myCustomDarkTheme,
    },
  },
});
