import axios from "axios";
import { useCookies } from "vue3-cookies";
import RepositoryFactory from "./repositories/RepositoryFactory";
const AuthRepository = RepositoryFactory("auth");
const UsersRepository = RepositoryFactory("users");
const QuizzesRepository = RepositoryFactory("quizzes");
const { cookies } = useCookies();

export async function loginUser(data) {
  return AuthRepository.loginUser(data)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function logOut() {
  return await AuthRepository.logOut()
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}

export async function getDiscordToken(code) {
  return await AuthRepository.getDiscordToken(code)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}

export async function getUserDiscord(token) {
  return axios
    .get(`https://discord.com/api/v9/users/@me`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      const user = res.data;
      return user;
    })
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}

export async function getUser() {
  return await UsersRepository.getUser()
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function checkQuiz(slug) {
  return await QuizzesRepository.checkQuiz(slug)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function startUserQuiz(data) {
  return await UsersRepository.startUserQuiz(data)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function checkUserQuiz(quizId) {
  return await UsersRepository.checkUserQuiz(quizId)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function addQuestionAnswer(data) {
  return await UsersRepository.addQuestionAnswer(data)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function getQuiz(slug) {
  return await QuizzesRepository.getQuiz(slug)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function getUserResumedAnswers(userQuizId) {
  return await UsersRepository.getUserResumedAnswers(userQuizId)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function submitQuiz(data) {
  return await UsersRepository.submitQuiz(data)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
}
export async function checkUserOnDiscord(token) {
  const server = process.env.VUE_APP_DISCORD_SERVER;
  const servers = await axios
    .get(`https://discord.com/api/v9/users/@me/guilds`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err.response.data.message };
    });
  const serverFound = servers.find((el) => el.id === server);
  if (serverFound) return true;
  return { err: true, message: "Not found user in server." };
}

export async function getTwitterUrl() {
  await AuthRepository.getTwitterRedirect()
    .then((res) => {
      window.location.replace(res.data.redirect);
    })
    .catch((err) => {
      return { err: true, message: err.response };
    });
}

export async function checkTwitter(route) {
  if (route.query && route.query.oauth_token && route.query.oauth_verifier) {
    return await AuthRepository.getTwitterAccessToken(
      route.query.oauth_token,
      route.query.oauth_verifier
    )
      .then((res) => {
        const data = {
          oauthAccessToken: res.data.access.oauthAccessToken,
          oauthRequestTokenSecret: res.data.access.oauthRequestTokenSecret,
          userId: res.data.access.userId,
          username: res.data.user.screen_name,
        };
        cookies.set("twitter_access", data);
        return data;
      })
      .catch((err) => {
        return { err: true, message: err.response.data.message };
      });
  } else if (cookies.get("twitter_access")) {
    return cookies.get("twitter_access");
  }

  return { err: true };
}
export async function checkTwitterFollow() {
  return await AuthRepository.checkTwitterFollow({
    userId: cookies.get("twitter_access").userId,
    oauthAccessToken: cookies.get("twitter_access").oauthAccessToken,
    oauthAccessTokenSecret:
      cookies.get("twitter_access").oauthRequestTokenSecret,
  })
    .then((res) => {
      return res.data[0].connections[0];
    })
    .catch((err) => {
      return { err: true, message: err?.response?.data?.message };
    });
}
