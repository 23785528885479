<template>
  <!--   <font-awesome-icon
    v-if="loading"
    style="color: #e97400; font-size: 50px"
    class="d-flex mx-auto"
    icon="fa-solid fa-spinner"
    spin-pulse
  ></font-awesome-icon> -->
  <h1
    v-if="error"
    class="d-flex justify-center text-center mx-auto"
    v-html="error"
    style="max-width: 1000px"
  ></h1>

  <div v-else>
    <LoginUser
      v-if="!$store.state.login"
      class="justify-center"
      :quiz="quiz"
      @on-error="onErrorDiscord"
    />
    <div v-else-if="$store.state.login" class="w-100 d-flex justify-center">
      <div v-if="!started && !resumed && !submited">
        <h1 v-if="quiz" class="text-center mb-5">{{ quiz.name }}</h1>
        <RulesList />
        <v-btn
          v-if="exitUserQuiz"
          class="d-block mx-auto mt-3"
          @click="resumeQuiz"
          >Resume quiz</v-btn
        >
        <v-btn v-else class="d-block mx-auto mt-2" @click="startQuiz"
          >Start quiz</v-btn
        >
      </div>
      <QuizQuestions
        v-else-if="(started || resumed) && !submited"
        :resumed="resumed"
        :started="started"
        :quiz="quiz"
        :userQuiz="exitUserQuiz"
        :loading="loading"
        @submit="submit"
      />
      <FinishCard
        v-else
        :userQuiz="exitUserQuiz"
        :quiz="quiz"
        :isSubmitNow="isSubmitNow"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import {
  checkQuiz,
  checkUserQuiz,
  submitQuiz,
} from "@/assets/scripts/functions";

import FinishCard from "@/components/FinishCard.vue";
import LoginUser from "@/components/LoginUser.vue";
import RulesList from "@/components/RulesList.vue";
import QuizQuestions from "@/components/QuizQuestions.vue";

export default defineComponent({
  name: "HomeView",
  components: { FinishCard, LoginUser, RulesList, QuizQuestions },
  setup() {
    const route = useRoute();
    const store = useStore();

    const error = ref();
    const quiz = ref();
    const exitUserQuiz = ref();

    const questions = ref([]);

    const started = ref(false);
    const resumed = ref(false);
    const submited = ref(false);
    const loading = ref(false);
    const isSubmitNow = ref(false);

    onMounted(async () => {
      loading.value = true;
      try {
        const res = await checkQuiz(route.params.slug);
        if (res.err) {
          if (res.message == "Quiz expired.") {
            error.value =
              "The Etherneals have made their choice and picked the worthy ones. Keep close to test your knowledge in the next challenge.";
          } else {
            error.value = res.message;
          }
        } else {
          quiz.value = res;
          await checkQuizStarted();
        }
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    });

    watch(
      () => store.state.login,
      (current) => {
        console.log("loginUser", current);
        if (current) checkQuizStarted();
      }
    );

    const checkQuizStarted = async () => {
      if (!store.state.login) return;
      const res = await checkUserQuiz(quiz.value.id);
      if (res.err) return;
      exitUserQuiz.value = res;
      if (exitUserQuiz.value.isSubmit) {
        submited.value = true;
      }
    };

    const startQuiz = async () => {
      started.value = true;
    };

    const resumeQuiz = async () => {
      resumed.value = true;
    };

    const submit = async (item) => {
      loading.value = true;
      const res = await submitQuiz(item);
      if (res.err) return;
      exitUserQuiz.value = res;
      if (exitUserQuiz.value.isSubmit) {
        submited.value = true;
      }
      isSubmitNow.value = true;
      loading.value = false;
    };

    const onErrorDiscord = () => {
      error.value = `
      <div>
        You are not a member of our Discord community. Please join our server first 
          <a href='https://discord.com/invite/fN4fn4PBcj' target="_blank" style="text-decoration: none; color: #f89430;"> Discord Server. </a> 
      </div>`;
    };

    return {
      started,
      questions,
      error,
      startQuiz,
      exitUserQuiz,
      resumeQuiz,
      resumed,
      quiz,
      submit,
      submited,
      onErrorDiscord,
      loading,
      isSubmitNow,
    };
  },
});
</script>
<style lang="scss"></style>
