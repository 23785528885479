import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { useCookies } from "vue3-cookies";
import { getUser } from "./assets/scripts/functions";
import "./assets/scss/index.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";

const { cookies } = useCookies();

loadFonts();

library.add(faSpinner, faCheck);

let user;
(async () => {
  if (cookies.get("jwt")) {
    user = await getUser();
    console.log("user", user);
    if (user && !user.err && user.statusCode != 401) store.state.login = user;
  } else {
    store.state.login = null;
  }
  const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(store)
    .use(vuetify);
  app.mount("#app");
})();
