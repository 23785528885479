<template>
  <h1 v-if="isSubmitNow" class="text-center">
    We are grateful to have you as a part of Etherneals. Keep close for the
    winners announcement!
  </h1>
  <h1 v-else class="text-center">
    You have already proven your knowledge. Keep close for winner announcements.
  </h1>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    userQuiz: { type: Object, requird: true },
    quiz: { type: Object, requird: true },
    isSubmitNow: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss">
.finish-card {
  background: #082a52 !important;
  padding: 20px;
  border-radius: 6px;
}
.quiz-name {
  color: #f89430 !important;
}
</style>
