<template>
  <div class="">
    <h1 v-if="quiz" class="text-center mb-5">{{ quiz.name }}</h1>
    <RulesList />
    <v-btn
      @click.stop="authorizeDiscord()"
      class="login-btn d-flex mx-auto mt-3"
    >
      <div v-if="loading" class="d-flex align-center justify-center">
        <v-img
          class="position-absolute"
          width="20"
          src="../assets/images/discord.svg"
        />
        <font-awesome-icon
          class="loading-icon"
          icon="fa-solid fa-spinner"
          spin-pulse
        />
      </div>
      <div v-else class="d-flex align-center">
        <v-img width="20" src="../assets/images/discord.svg" />
        Login with Discord
      </div>
    </v-btn>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";

import {
  checkUserOnDiscord,
  getDiscordToken,
  getUserDiscord,
  loginUser,
} from "@/assets/scripts/functions";
import Repository from "@/assets/scripts/repositories/Repository";
import RulesList from "./RulesList.vue";

export default {
  components: { RulesList },
  props: {
    quiz: { type: Object, requird: true },
  },
  setup(props, context) {
    const { cookies } = useCookies();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const loading = ref(false);

    const discordUser = computed(() => store.state.discordUser);

    onMounted(() => {
      checkDiscordCode();
    });

    const checkDiscordCode = async () => {
      loading.value = true;
      const code = route.query.code;
      if (code && route.params.slug) {
        const res = await getDiscordToken(code);

        if (!res.err) {
          const isOnDiscord = await checkUserOnDiscord(res.access_token);
          if (isOnDiscord.err) {
            context.emit("on-error");
          } else {
            const user = await getUserDiscord(res.access_token);
            if (!user.err) {
              const obj = {
                discordId: user.id,
                discordUsername: user.username,
                discordEmail: user.email,
                discordImg: user.avatar,
              };

              const userLogin = await loginUser(obj);
              if (!userLogin.err) {
                store.state.login = userLogin.user;
                cookies.set("jwt", userLogin.access_token);
                Repository.defaults.headers = {
                  Authorization: `Bearer ${cookies.get("jwt")}`,
                };
              }
            }
          }
          router.push(`/quizzes/${route.params["slug"]}`);
        }
        router.push(`/quizzes/${route.params["slug"]}`);
      }
      loading.value = false;
    };

    const authorizeDiscord = () => {
      window.open(
        `${process.env.VUE_APP_DISCORD_URL}&state=${route.params["slug"]}`,
        "_self"
      );
    };

    return {
      cookies,
      discordUser,
      loading,
      authorizeDiscord,
      checkDiscordCode,
    };
  },
  data() {
    return {
      model: 1,
    };
  },
};
</script>
<style lang="scss">
.login-btn {
  background: linear-gradient(#f89430, #e97400) !important;
}
.loading-icon {
  font-size: 35px !important;
}
.align-buttons {
  align-items: center;
  display: flex;
}
.user-btn {
  background-color: #99e9fc !important;
  color: #1b1d2c !important;
  border-radius: 6px !important;
}
.user-menu-list {
  background-color: #181a2c !important;
  .user-menu-list_item {
    font-size: 14px !important;
    cursor: pointer;
  }
}
</style>
