import AuthRepository from "./AuthRepository";
import QuizzesRepository from "./QuizzesRepository";
import UsersRepository from "./UsersRepository";

const repositories = {
  auth: AuthRepository,
  users: UsersRepository,
  quizzes: QuizzesRepository,
};
export default function RepositoryFactory(name) {
  return repositories[name];
}
