import Repository from "./Repository";

export default {
  loginUser(data) {
    return Repository.post(`/auth/login/user`, data);
  },
  logOut() {
    return Repository.post(`/auth/logout`);
  },
  getUser() {
    return Repository.get(`/user`);
  },
  getDiscordToken(code) {
    return Repository.get(`/auth/discord/token/${code}`);
  },
  getTwitterRedirect() {
    return Repository.get(`/auth/twitter`);
  },
  getTwitterAccessToken(oauthToken, oauthVerifier) {
    return Repository.get(
      `auth/twitter/access-token?oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`
    );
  },
  checkTwitterFollow(data) {
    return Repository.post(`/auth/twitter/check-follow`, data);
  },
};
