import { createRouter, createWebHistory } from "vue-router";
import QuizView from "../views/QuizView.vue";

const routes = [
  {
    path: "/quizzes",
    name: "Index",
    component: () => import("../views/IndexView.vue"),
  },
  {
    path: "/quizzes/:slug",
    name: "quizzes",
    component: QuizView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
